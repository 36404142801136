export class Accordion {
  constructor(selector, wrapper) {
    this.wrapper = document.querySelector(wrapper)
    this.selectors = this.wrapper.querySelectorAll(selector)

    this.toggleElement(this.selectors)
  }

  toggleElement(selectors) {
    selectors.forEach((selector) => {
      selector.addEventListener("click", this.handleElementClick)
    })
  }

  handleElementClick(e) {
    e.preventDefault()
    let target = e.target && e.target.closest("[data-accordion-item]")

    if (!target.classList.contains("accordion-item--open")) {
      let otherElements = document.querySelectorAll(".accordion-item--open")
      otherElements.forEach((el) => {
        if (el !== target.closest("[data-toggle-element]")) {
          console.log(el)
          el.classList.remove("accordion-item--open")
        }
      })

      target.classList.add("accordion-item--open")
    } else {
      target.classList.remove("accordion-item--open")
      console.log("close")
    }
  }
}
