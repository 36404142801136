export function initForms() {
  let form = document.querySelector(".form")
  if (!form) {
    return
  }

  let input = form.querySelectorAll(".field > input, .field > textarea")
  let radioCheckboxFields = form.querySelectorAll(
    "input[type=radio], input[type=checkbox]",
  )

  if (radioCheckboxFields) {
    window.addEventListener("change", () => {
      radioCheckboxFields.forEach((e) => {
        if (e.checked) {
          e.parentNode.classList.add("--filled")
          e.parentNode.parentNode.classList.remove("field--error")
        } else {
          e.checked = false
          e.parentNode.classList.remove("--filled")
        }
      })
    })

    radioCheckboxFields.forEach((e) => {
      const span = document.createElement("span")
      span.classList.add("field__input")
      e.parentNode.appendChild(span)
      span.addEventListener("click", () => {
        const event = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        e.dispatchEvent(event)
      })

      e.setAttribute("tabindex", 0)

      if (e.checked) {
        e.parentNode.classList.add("--filled")
        e.parentNode.parentNode.classList.remove("field--error")
      } else {
        e.parentNode.classList.remove("--filled")
      }
    })
  }

  if (input) {
    window.addEventListener("change", () => {
      input.forEach((e) => {
        if (!e.value == "") {
          e.classList.add("--filled")
          e.parentNode.parentNode.classList.remove("field--error")
        } else {
          e.classList.remove("--filled")
        }
      })
    })

    input.forEach((e) => {
      e.setAttribute("tabindex", 0)
      if (e.value != "") {
        e.classList.add("--filled")
        e.parentNode.parentNode.classList.remove("field--error")
      } else {
        e.classList.remove("--filled")
      }
    })
  }

  function autoResize() {
    this.style.height = "auto"
    this.style.height = `${this.scrollHeight}px`
  }

  let textAreaFields = document.querySelectorAll(
    "[data-textarea-autoresize] textarea",
  )
  textAreaFields.forEach((textarea) => {
    textarea.addEventListener("input", autoResize, false)
  })
}
