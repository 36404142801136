/* global gettext */
import { render } from "preact"
import { sessionStorage } from "./storage"
import "./solutionNavigation.css"

const LANGUAGE_CODE = window.location.pathname.split("/")[1]

export const initSolutionNavigation = () => {
  const navs = Array.from(document.querySelectorAll(".solution-navigation"))

  if (navs.length > 0) {
    navs.map((nav) => {
      render(<SolutionNavigation id={nav.dataset.id} />, nav)
    })
  }
}

const correctLanguageContext = (url) => {
  const urlContext = url.split("/")[1]
  return LANGUAGE_CODE !== urlContext
    ? url.replace(urlContext, LANGUAGE_CODE)
    : url
}

const SolutionNavigation = ({ id }) => {
  const solutions = JSON.parse(
    sessionStorage.getItem("spotlight-ordering"),
  )?.items
  const selfIndex = solutions ? solutions.findIndex((s) => s.id == id) : 0
  const nextItem = solutions
    ? selfIndex < solutions.length - 1
      ? solutions[selfIndex + 1]
      : solutions[0]
    : false
  const previousItem = solutions
    ? selfIndex > 0
      ? solutions[selfIndex - 1]
      : solutions[solutions.length - 1]
    : false

  return (
    <>
      {solutions && (
        <div class="solution-navigation__wrapper">
          {previousItem && (
            <div class="solution-navigation__item--left">
              <a
                class="no-decoration content-breadcrumb"
                href={correctLanguageContext(previousItem.url)}
              >
                <i class="control icon icon--small icon--arrow-left-black" />
                {gettext("previous idea")}
              </a>
            </div>
          )}
          {nextItem && (
            <div class="solution-navigation__item--right">
              <a
                class="no-decoration content-breadcrumb"
                href={correctLanguageContext(nextItem.url)}
              >
                {gettext("next idea")}
                <i class="control icon icon--right icon--small icon--arrow-right-black" />
              </a>
            </div>
          )}
        </div>
      )}
    </>
  )
}
