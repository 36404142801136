/* global grecaptcha, gettext */
import { getCookie } from "./utils"

function showErrors(form, errors) {
  for (let k in errors) {
    let ul = document.createElement("ul")
    ul.classList.add("errorlist")

    errors[k].forEach((e) => {
      let li = document.createElement("li")
      li.appendChild(document.createTextNode(e))
      ul.appendChild(li)
    })

    if (k == "__all__") {
      let firstChild = form.children[0]

      if (firstChild.classList.contains("errorlist")) {
        form.replaceChild(ul, firstChild)
      } else {
        form.insertBefore(ul, firstChild)
      }
    } else {
      let input = form.querySelector(`[name=${k}]`)
      input.after(ul)
    }
  }
  const error_list = document.querySelector(".errorlist")
  error_list.scrollIntoView({ block: "start" })
}
function reload_captcha(form) {
  const public_key = form.getAttribute("data-captcha-key")
  const recaptcha_widget = document.querySelector(".g-recaptcha")
  const widget_uuid = recaptcha_widget.getAttribute("data-widget-uuid")

  grecaptcha.execute(public_key, { action: "submit" }).then((token) => {
    console.log(
      "reCAPTCHA validated for 'data-widget-uuid=\"{{ widget_uuid }}\"'. Setting input value...",
    )
    const element = document.querySelector(
      `.g-recaptcha[data-widget-uuid="${widget_uuid}"]`,
    )
    element.value = token
  })
}

function sendForm(form) {
  let data = {
    csrfmiddlewaretoken: form.csrfmiddlewaretoken.value,
    votes: form.votes.value,
    first_name: form.first_name.value,
    last_name: form.last_name.value,
    email: form.email.value,
    mobile: form.mobile.value,
    contest: form.contest.value,
    newsletter: form.newsletter.checked,
    terms_and_conditions: form.terms_and_conditions.checked,
    participate_in_raffle: form.participate_in_raffle.checked,
    captcha: form.captcha.value,
  }

  const vote_button = document.querySelector("#vote_button")
  vote_button.disabled = true

  fetch(form.action, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCookie("csrftoken"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.status == 400) {
        response.json().then((data) => {
          showErrors(form, data)
        })
      } else if (response.status == 201) {
        response.json().then((data) => {
          if (data.status === "success") {
            window.localStorage.removeItem("votinglist")
            window.location.replace(data.success_url)
          } else {
            showErrors(form, {
              __all__: [data.message],
            })
            reload_captcha(form)
            // let successElement = document.createElement("div")
            // successElement.innerHTML = data.message
            // document
            //   .querySelector("[data-voting-form]")
            //   .replaceWith(successElement)
          }
        })
      } else {
        return Promise.reject(response)
      }
      vote_button.disabled = false
    })
    .catch((_response) => {
      console.log(_response)
      showErrors(form, {
        __all__: [gettext("An error occurred. Please check your submission.")],
      })
      vote_button.disabled = false
    })
}

function initVotingForm() {
  const votingForm = document.querySelector("[data-voting-form]")

  if (votingForm) {
    votingForm.addEventListener("submit", (e) => {
      e.preventDefault()
      sendForm(votingForm)
    })
    // reload captcha every 2 minutes
    setTimeout(() => {
      reload_captcha(votingForm)
    }, 120000)
  }
}

export default initVotingForm
